import customTheme from "@/styles/customTheme";
import fetcher from "@/helpers/fetcher";
import useSWR from "swr";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import withAuthUser from "@/helpers/authHandler/withAuthUser";
import Router from "next/router";
import { ThemeProvider } from "@mui/material/styles";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";

import setLanguage from "next-translate/setLanguage";
import LazyLoader from "@/helpers/loader/lazyLoader";
import DynamicLoader from "@/helpers/loader/dynamicLoader";

const LoadingComponent = () => <DynamicLoader width={"100%"} height={300} />;

const LabelBottomNavigation = dynamic(()=> import("@/components/Navigation/LabelBottomNavigation"));
const AddToHomePage: any = dynamic(() => import("../AddToHomepage/addHomepage"), {
    ssr: false,
});
const FooterPage = dynamic(() => import("@/components/Footer/footer"), {
    ssr: false,
    loading: LoadingComponent,
});
const DesktopHeader = dynamic(() => import("@/components/Navigation/DesktopHeader"), {
    ssr: false,
});
const MobileHeader = dynamic(() => import("@/components/Navigation/MobileHeader"));

const Layout = ({ children }: any) => {
    const { data } = useSWR("userData", () => fetcher("/api/getUserData"));
    const [openPopUp, setOpenPopUp] = useState(false);
    const [pageCounter, setPageCounter] = useState(1);
    const [showMenu, setShowMenu] = useState(true);
    const [showFooter, setShowFooter] = useState(true);
    const theme = useTheme();
    const { lang } = useTranslation("translation");
    const isLargeSize = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        const handleRouteChangeStart = (url: string) => {
            if (!url.includes("finnlop")) {
                sessionStorage.setItem("previouseRoute", url);
            }
            window.scrollTo({ top: 0 });
            setPageCounter((prev) => prev + 1);
        };

        const handleRouteChangeComplete = () => {
            const url = window.document.URL;
            const hideMenuAndFooter =
                url.includes("utm_") ||
                url.includes("organizer-portal") ||
                url.includes("edit-run");
            setShowMenu(!hideMenuAndFooter);
            setShowFooter(!hideMenuAndFooter);
        };

        Router.events.on("routeChangeStart", handleRouteChangeStart);
        Router.events.on("routeChangeComplete", handleRouteChangeComplete);

        return () => {
            Router.events.off("routeChangeStart", handleRouteChangeStart);
            Router.events.off("routeChangeComplete", handleRouteChangeComplete);
        };
    }, []);

    useEffect(() => {
        const url = window.document.URL;
        const hideMenuAndFooter =
            url.includes("utm_") ||
            (url.includes("organizer-portal") && !url.includes("organizer-portal-news")) ||
            url.includes("edit-run");
        setShowMenu(!hideMenuAndFooter);
        setShowFooter(!hideMenuAndFooter);

        const preferrefLanguage = localStorage.getItem("preferredLanguage");
        if (preferrefLanguage && preferrefLanguage !== lang) {
            setLanguage(preferrefLanguage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const read_message = () => {
        setOpenPopUp(false);
        localStorage.setItem("message", "true");
    };

    const open_message = () => {
        const data = localStorage.getItem("message");
        if (data) {
            setOpenPopUp(false);
        } else {
            setOpenPopUp(true);
        }
    };

    return (
        <ThemeProvider theme={customTheme}>
            <>
                <Box
                    sx={{
                        height: {
                            xs: showMenu ? "120px" : "0px",
                            lg: !showMenu ? "0px" : "170px",
                        },
                        zIndex: 1100,
                        width: "100vw",
                    }}
                >
                    {isLargeSize && showMenu && (
                        <DesktopHeader
                            authUser={data}
                            openPopUp={false}
                            read_message={read_message}
                        />
                    )}
                    {!isLargeSize && showMenu && (
                        <MobileHeader
                            authUser={data}
                            openPopUp={openPopUp}
                            read_message={read_message}
                        />
                    )}
                </Box>
                <main style={{ minHeight: "70vh" }}>
                    {<Box sx={{ backgroundColor: "#efefef" }}>{children}</Box>}
                </main>
                <footer>
                    {showFooter && (
                        <LazyLoader>
                            <FooterPage />
                        </LazyLoader>
                    )}
                </footer>
                <AddToHomePage pageCounter={pageCounter} open_message={() => open_message()} />
                {showMenu && <LabelBottomNavigation authUser={data} />}
            </>
        </ThemeProvider>
    );
};

export default withAuthUser()(Layout);
