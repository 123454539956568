import axios from "axios";

const fetcher = (url: string) => {

     let default_config:any = {
        url: url,
        method: "post",
        timeout: 5000
    };
    
    const promise = axios(default_config);

    const response = promise
        .then((response: any) => response.data)
        .catch((e)=> console.log(e));
        
    return response;
}

export default fetcher;