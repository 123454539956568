
import { createTheme } from "@mui/material/styles";
import "@fontsource/open-sans";

const deepPetrol = "#007E80";
const white = "#FCFCFC";
const gray = "#EFEFEF";
const deepOrange = "#BE5618";
const softOrange = "#FF8640";
const primaryColor = "#222222";
const lightOrange = "#fcf0e9";

const customTheme = createTheme({
    palette: {
        primary: {
            // light: "#757ce8",
            main: primaryColor,
            // dark: "#000000",
            contrastText: "#fff",
        },
        warning: {
            main: softOrange,
            contrastText: "#fff",
        },
        info: {
            main: "#525252",
        },
        secondary: {
            light: "#EFEFEF",
            main: deepPetrol,
            dark: "#ba000d",
            contrastText: "#000",
        },
    },

    zIndex: { snackbar: 1000 },
    typography: {
        fontFamily: "'Open Sans'",
    },

    components: {
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: "unset",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "6px",
                    textTransform: "unset",
                    height: "40px",
                    fontSize: "16px",
                    fontWeight: "600",
                },
                containedPrimary: {
                    backgroundColor: primaryColor,
                    color: white,
                    minWidth: "100px",
                    "&:hover": {
                        color: "white",
                        textDecoration: "none",
                    },
                },
                outlinedPrimary: {
                    border: "1px solid softOrange",
                    color: primaryColor,
                    minWidth: "100px",
                    "&:hover": {
                        textDecoration: "none",
                        backgroundColor: white,
                        color: primaryColor,
                    },
                },
                containedSecondary: {
                    backgroundColor: deepPetrol,
                    color: white,
                    minWidth: "200px",
                    maxWidth: "300px",
                    "&:hover": {
                        backgroundColor: white,
                        color: deepPetrol,
                    },
                },
                outlinedSecondary: {
                    "&:hover": {
                        backgroundColor: deepPetrol,
                        color: white,
                    },
                },
                containedInherit: {
                    color: primaryColor,
                    backgroundColor: white,
                    "&:hover": {
                        backgroundColor: deepOrange,
                        color: white,
                    },
                },
                outlinedInherit: {
                    border: "1px solid white",
                    color: white,
                    minWidth: "200px",
                    maxWidth: "300px",
                    "&:hover": {
                        backgroundColor: white,
                        color: deepPetrol,
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: "20px",
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    border: "3px solid #222222",
                    borderRadius: "10px",
                },
            },
        },

        MuiSnackbarContent: {
            styleOverrides: {
                root: {
                    color: "#FFFFFF",
                    borderRadius: "10px",
                },
            },
        },
        MuiRating: {
            styleOverrides: {
                decimal: { padding: "2px" },
                iconFilled: { width: "20px" },
            },
        },
        // MuiInputBase: {
        //     styleOverrides: {
        //         root:{
        //             fontSize: "20px",
        //         }
        //     },
        // },
    },
});

customTheme.typography.h1 = {
    fontSize: "2.8rem",
    fontWeight: "600",
    [customTheme.breakpoints.up("sm")]: {
        fontSize: "4rem",
    },
    [customTheme.breakpoints.up("lg")]: {
        fontSize: "6rem",
    },
};
customTheme.typography.h3 = {
    fontSize: "2.3rem",
    fontWeight: "600",
    [customTheme.breakpoints.up("md")]: {
        fontSize: "2.7rem",
    },
    [customTheme.breakpoints.up("lg")]: {
        fontSize: "3rem",
    },
};
customTheme.typography.h4 = {
    textTransform: "unset",
    fontWeight: "600",
    lineHeight: "35px",
    fontSize: "1.5rem",
    [customTheme.breakpoints.up("md")]: {
        fontSize: "2rem",
    },
};
customTheme.typography.h5 = {
    fontSize: "1rem",
    fontWeight: "400",
    [customTheme.breakpoints.up("md")]: {
        fontSize: "1.2rem",
    },
};
customTheme.typography.h6 = {
    fontSize: "0.9rem",
    fontWeight: "600",
    [customTheme.breakpoints.up("lg")]: {
        fontSize: "1rem",
    },
};

customTheme.typography.body1 = {
    fontSize: "14px",
};

customTheme.typography.body2 = {
    fontSize: "11px",
};

export default customTheme;
